<template>
  <q-layout view="lHh LpR lFf" class="rtl" style="font-family: 'bdyFnt', sans-serif;">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" class="q-mr-sm"/>
        <q-toolbar-title>گارسون یار</q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="leftDrawerOpen" bordered side="right" style="font-family: 'bdyFnt', sans-serif;">
      <q-list>
        <q-item-label header>دستیار</q-item-label>

      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="q-pa-md" style="background-color: #f2f2f2; overflow: auto;">
        <div class="row q-col-gutter-md">
          <div class="col-xs-12 col-sm-6">
            <q-card class="events-card shadow-10">
              <q-card-section>
                <div class="text-h6 text-brown-14">رویدادها</div>
              </q-card-section>
              <q-card-section>
                <q-list>
                  <q-item v-for="event in events" :key="event.id" class="q-mb-sm">
                    <q-item-section avatar>
                      <q-icon name="event" color="brown-14" size="sm"/>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>{{ event.title }}</q-item-label>
                      <q-item-label caption class="text-brown-14">{{
                          formattedTimeDifference(event.fromDate)
                        }}
                      </q-item-label>
                      <q-item-label caption>میز {{ event.deskName }} ({{ event.deskNum }})</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-btn flat round color="brown-14" icon="close" @click="hideEvent(event)"/>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
          </div>

          <div class="col-xs-12 col-sm-6">
            <q-card class="low-stock-card shadow-10">
              <q-card-section>
                <div class="text-h6 text-orange-14">موجودی رو به اتمام</div>
              </q-card-section>
              <q-card-section>
                <q-list>
                  <q-item v-for="product in lowStockProducts" :key="product.id" class="q-mb-sm">
                    <q-item-section avatar>
                      <q-icon name="warning" color="orange-14" size="sm"/>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>{{ product.name }}</q-item-label>
                      <q-item-label caption>موجودی: {{ product.stock }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-page>
    </q-page-container>
    <q-footer elevated class="bg-grey-9 text-white">
      <q-toolbar>
        <router-link to="/" class="q-mr-sm text-white" active-class="text-white">
          <q-btn flat round dense icon="home" class="q-mr-sm"/>
        </router-link>

        <q-btn flat round dense icon="favorite" class="q-mr-sm"/>

        <router-link to="/order" class="q-mr-sm text-white" active-class="text-white">
          <q-btn flat round dense icon="shopping_cart" class="q-mr-sm"/>
        </router-link>

        <q-space/>
        <q-btn flat round dense icon="settings"/>
      </q-toolbar>
    </q-footer>
  </q-layout>

</template>
<script>
import ApiService from "@/services/ApiService";
import {useQuasar} from "quasar";
import moment from "moment";
import SocketService from '@/services/SocketService';
import {EventBus} from '@/services/event-bus';

export default {
  name: "sUJH",
  created() {
    SocketService.connect();
    EventBus.on('newEvent', this.handleNewEvent);
  },
  beforeUnmount() {
    EventBus.off('newEvent', this.handleNewEvent);
  },
  data() {
    return {
      timeDifference: {},
      stompClient: null,
      leftDrawerOpen: false,
      q: useQuasar(),
      events: [
        {id: 1, title: 'رویداد 1', time: '10 دقیقه پیش'},
      ],
      lowStockProducts: [{id: 1, name: 'محصول 1', stock: 5}, {
        id: 2,
        name: 'محصول 2',
        stock: 3
      }],
    }
  },
  methods: {
    handleNewEvent(event) {
      const existingEvent = this.events.find(e => e.id === event.id);
      if (!existingEvent) {
        this.events.unshift(event);
      }
    },
    formattedTimeDifference(fromdate) {
      const eventTime = moment(fromdate);
      return eventTime.format("HH:mm");
    },


    toggleLeftDrawer() {
      this.leftDrawerOpen = !this.leftDrawerOpen;
    },
    getToken() {
      const storedToken = localStorage.getItem('authToken');
      if (!storedToken) {
        return null;
      }
      return storedToken;
    },
    hideEvent(selectedEvent) {
      const deleteEventRequest = {
        id: selectedEvent.id,
        name: selectedEvent.title,
      };


      let config = {
        headers: {
          'Authorization': `Bearer ${this.getToken()}`,
          'Content-Type': 'application/json'
        }
      }
      const requestBody = JSON.stringify(deleteEventRequest);
      ApiService.post("/events/thruDateEvent", requestBody, config).then(response => {
        this.events = this.events.filter(event => event.id !== selectedEvent.id);
        this.q.notify({
          type: 'positive',
          html: true,
          classes: "notify-font",
          message: response.message,
          position: 'bottom',
          progress: false
        })
      }).catch(error => {
        this.q.notify({
          type: 'negative',
          html: true,
          classes: "notify-font",
          message: error.message,
          position: 'bottom',
          progress: true
        })
      })


    },
    getAllEventsFromServer() {
      return new Promise(resolve => {
        let config = {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`,
            'Content-Type': 'application/json'
          }
        }
        ApiService.get("/events/getAllEvents", config)
            .then(response => {
              console.log(response);
              this.events = response.events
              resolve();
            })
            .catch(error => {
              // window.location.reload();
              console.log(error);
              this.q.notify({
                type: 'negative',
                html: true,
                classes: "notify-font",
                message: error.message,
                position: 'bottom',
                progress: true
              })
            })
      });
    },
  }
  ,
  mounted() {
    Promise.all([
      this.getAllEventsFromServer(),
      document.fonts.ready,
      document.images,
      // new Promise(resolve => window.addEventListener('load', resolve))
    ]).then(() => {
      console.log(".then(()"),
          this.loading = false;
    });
  },
}
</script>
<style scoped> .events-card {
  background-color: #F5F5DC;
}

.low-stock-card {
  background-color: #FAEBD7;
}

.my-active-class .q-btn {
  color: inherit;
}

.rtl {
  direction: rtl;
}

@font-face {
  font-family: 'bdyFnt';
  src: url('/src/assets/Vazir.ttf') format('opentype');
}

.body-font {
  font-family: 'bdyFnt', sans-serif;
} </style>
