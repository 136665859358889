import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'

import { Notify, Quasar } from 'quasar'
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'

import { loadFonts } from './plugins/webfontloader'
import Vue from "@vue/cli-plugin-eslint/eslintOptions";



Vue.config.lang = 'fa'
Vue.config.rtl = true
loadFonts()

// ثبت Service Worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js')
            .then(function(registration) {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(function(error) {
                console.log('Service Worker registration failed:', error);
            });
    });
}

createApp(App)

    .use(router)
    .use(vuetify)
    .use(Quasar, {
        plugins: {
            Notify
        }
    })
    .use(Quasar)
    .mount('#app')
