import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';
import ApiService from "@/services/ApiService";
import {EventBus} from './event-bus';

class SocketService {
    constructor() {
        this.stompClient = null;
    }

    connect() {
        let socketUrl = ApiService.getBaseUrlSocket();
        const socket = new SockJS(socketUrl);
        this.stompClient = Stomp.over(socket);
        const headers = {
            'Authorization': `Bearer ${this.getToken()}`,
            'Content-Type': 'application/json'
        };
        this.stompClient.connect(headers, frame => {
            console.log('Connected: ' + frame);
            this.stompClient.subscribe('/topic/events', event => {
                this.handleEvent(JSON.parse(event.body));
            });
        });
    }

    handleEvent(event) {
        EventBus.emit('newEvent', event);
        const audio = new Audio(require('@/assets/notif.wav'));
        audio.play();
        console.log('Received event:', event);
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.controller.postMessage({
                type: 'SHOW_NOTIFICATION',
                notificationData: {
                    title: 'رویداد جدید',
                    body: event.title,
                    icon: require('@/assets/he.png')
                }
            });
        }

    }

    getToken() {
        const storedToken = localStorage.getItem('authToken');
        if (!storedToken) {
            return null;
        }
        return storedToken;
    }
}

export default new SocketService();
