import axios from 'axios';
import ErrorService from './ErrorService';

let BASE_URL = '';
if (process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    BASE_URL = 'http://127.0.0.1:1020';
} else {
    BASE_URL = 'https://emaratesaran.ir:1020';
}


const DEFAULT_TIMEOUT = 20000;

class ApiService {
    constructor() {
        this.axiosInstance = axios.create({
            baseURL: BASE_URL, timeout: DEFAULT_TIMEOUT,
        });
    }

    async post(url, data, config) {
        try {
            const response = await this.axiosInstance.post(url, data, config);
            return this.handleResponse(response);
        } catch (error) {
            console.log(JSON.stringify(error))
            throw ErrorService.handleNetworkError(error);
        }
    }

    async get(url, config) {
        try {
            const response = await this.axiosInstance.get(url, config);
            return this.handleResponse(response);
        } catch (error) {
            console.log(JSON.stringify(error))
            throw ErrorService.handleNetworkError(error);
        }
    }

    handleResponse(response) {
        if (response.status === 200 || response.status === 201) {
            return response.data;
        }
        throw ErrorService.handleApiError(response);
    }

    getBaseUrl() {
        return BASE_URL;
    }

    getBaseUrlSocket() {
        return BASE_URL + "/ws";
    }

}

export default new ApiService();
