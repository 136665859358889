<template dir="rtl">
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  },
  data: () => ({
    //
  }),
}
</script>
