import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/Home.vue'

const routes = [{
    path: '/', name: 'home', component: HomeView
}, {
    path: '/t', name: 'test', component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
}, {
    path: '/login', name: 'login', component: () => import(/* webpackChunkName: "test" */ '../views/Login.vue')
}, {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "test" */ '../views/OrderCart.vue')
}]

const router = createRouter({
    history: createWebHashHistory(), routes
})

export default router
